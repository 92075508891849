import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { LoadingSpinnerComponent } from '@array-app/frontend/common';
import { AlgoliaUser } from '@array-app/shared/types';
import { InitialsPipe } from '../../pipes';

@Component({
	standalone: true,
	selector: 'app-algolia-user-avatar',
	imports: [CommonModule, InitialsPipe, LoadingSpinnerComponent],
	templateUrl: 'algolia-user-avatar.component.html',
	styleUrls: ['algolia-user-avatar.component.scss']
})
export class AlgoliaUserAvatarComponent {
	@Input() user: AlgoliaUser | null = null;

	@Input() loading = false;

	@HostBinding('class')
	@Input()
	size: 'xsmall' | 'small' | 'medium' | 'large' = 'small';
}
