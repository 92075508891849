import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';
import {
	LoadingSpinnerComponent,
	RelativeTimestampPipe,
	TextBadgeComponent
} from '@array-app/frontend/common';
import { OrganizationWordmarkComponent } from '@array-app/frontend/organizations';
import { InviteWithOrganization } from '@array-app/shared/types';
import { TranslateModule } from '@ngx-translate/core';
import { QuillModule } from 'ngx-quill';

@Component({
	standalone: true,
	selector: 'app-invite-item',
	imports: [
		CommonModule,
		RelativeTimestampPipe,
		TextBadgeComponent,
		LoadingSpinnerComponent,
		TranslateModule,
		QuillModule,
		OrganizationWordmarkComponent
	],
	templateUrl: 'invite-item.component.html',
	styleUrls: ['invite-item.component.scss']
})
export class InviteItemComponent {
	@Input() invite!: InviteWithOrganization;

	/**
	 * `true` if there is a request active pertaining to an action in this item
	 */
	@Input() busy = false;

	/**
	 * `true` if the user can have the option to navigate as an action instead of
	 * accept or decline. Used for when the user has accepted and can safely navigate
	 * to the organization now
	 */
	@Input() allowNavigate = false;

	@Output() accept = new EventEmitter<void>();
	@Output() decline = new EventEmitter<void>();
	@Output() navigateTo = new EventEmitter<void>();

	get isExpired() {
		return this.invite.expiresAt < Timestamp.now();
	}

	onAccept() {
		if (!this.isExpired) {
			this.accept.emit();
		}
	}

	onDecline() {
		if (!this.isExpired) {
			this.decline.emit();
		}
	}

	onNavigateTo() {
		this.navigateTo.emit();
	}
}
