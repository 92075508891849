import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { TextBadgeComponent } from '@array-app/frontend/common';
import { Status, StatusType, User } from '@array-app/shared/types';
import { status } from '@array-app/shared/utils';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	standalone: true,
	selector: 'app-user-status',
	imports: [CommonModule, TextBadgeComponent, TranslateModule],
	templateUrl: 'user-status.component.html',
	styleUrls: ['user-status.component.scss']
})
export class UserStatusComponent {
	@Input() user!: User;

	@HostBinding('class')
	@Input()
	size: 'medium' | 'small' = 'medium';

	get status(): { type: StatusType; text: string } {
		const userStatus = status(this.user, { includeDeletion: true });
		switch (userStatus) {
			case 'deleted':
				return {
					type: Status.Danger,
					text: 'users.status.deleted'
				};
			case 'rejected':
				return {
					type: Status.Danger,
					text: 'users.status.rejected'
				};
			case 'disabled':
				return {
					type: Status.Danger,
					text: 'users.status.disabled'
				};
			case 'pending':
				return {
					type: Status.Caution,
					text: 'users.status.pending'
				};
			default:
			case 'active':
				return {
					type: Status.Success,
					text: 'users.status.enabled'
				};
		}
	}
}
