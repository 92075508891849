import { Pipe, PipeTransform } from '@angular/core';
import { User } from '@array-app/shared/types';
import { fullName, FullNameOptions } from '@array-app/shared/utils';

@Pipe({
	standalone: true,
	name: 'fullName'
})
export class FullNamePipe implements PipeTransform {
	transform(user: User, options?: FullNameOptions) {
		return fullName(user, options);
	}
}
