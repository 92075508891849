import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '@array-app/frontend/authentication';
import { User } from '@array-app/shared/types';

/**
 * Determines if the user provided is a super user or not. If no value is provided we will
 * use the currently authenticated user to determine.
 *
 * For use on current auth user
 * {{ null | isAdmin }}
 */
@Pipe({
	standalone: true,
	name: 'isAdmin'
})
export class IsAdminPipe implements PipeTransform {
	constructor(private readonly authService: AuthService) {}

	transform(user: User | undefined) {
		if (!user) {
			user = this.authService.user$?.value;
		}

		switch (user?.role) {
			case 'admin':
			case 'owner':
			case 'super':
				return true;
			default:
				return false;
		}
	}
}
