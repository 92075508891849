<app-resource-list-item [disabled]="disabled">
	<ng-container *ngIf="clickable">
		<button type="button" [disabled]="disabled" (click)="onSelect()">
			<ng-container *ngTemplateOutlet="content"></ng-container>
		</button>
	</ng-container>
	<ng-container *ngIf="!clickable">
		<div>
			<ng-container *ngTemplateOutlet="content"></ng-container>
		</div>
	</ng-container>
</app-resource-list-item>

<ng-template #content>
	<div start>
		<app-user-avatar [user]="user"></app-user-avatar>
	</div>
	<app-title-description
		[title]="user | fullName"
		[description]="
			(user.position ? user.position + ' • ' : '') +
			(user.role | titlecase)
		"
	></app-title-description>

	<div end>
		<app-text-badge
			*ngIf="user.deletedAt"
			type="danger"
			class="margin-right-2"
		>
			{{ 'generic.deleted' | translate }}
		</app-text-badge>

		<ng-content *ngIf="!user.deletedAt"></ng-content>

		<ng-content select="[static]"></ng-content>
	</div>
</ng-template>
