import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { PageTitleResolve } from '@array-app/frontend/common';
import { User } from '@array-app/shared/types';
import { fullName } from '@array-app/shared/utils';
import { TranslateService } from '@ngx-translate/core';
import { UserAdminApiService } from '../services';

@Injectable({ providedIn: 'root' })
export class UserPageTitleResolve extends PageTitleResolve {
	constructor(
		protected override readonly translate: TranslateService,
		private readonly userAdminService: UserAdminApiService,
		private readonly router: Router
	) {
		super(translate);
	}

	override async resolve(route: ActivatedRouteSnapshot) {
		const userId = route.params['id'];
		const user = await this.userAdminService
			.findById(userId)
			.then((res) => res.data() as User);

		if (!user) {
			this.router.navigate(['app/users']);
			return '';
		}

		document.title =
			this.pageTitleBase +
			this.translate.instant('route.title.users') +
			' | ' +
			fullName(user);
		return document.title;
	}
}
