import { Pipe, PipeTransform } from '@angular/core';
import { User } from '@array-app/shared/types';
import { AuthService } from '@array-app/frontend/authentication';

@Pipe({
	standalone: true,
	name: 'isAuthUser'
})
export class IsAuthUserPipe implements PipeTransform {
	constructor(private readonly authService: AuthService) {}

	transform(user: User) {
		return user.id === this.authService.user$?.value?.id;
	}
}
