import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import {
	CollectionReference,
	Firestore,
	Timestamp,
	collection,
	getDocs,
	query,
	where
} from '@angular/fire/firestore';
import { AuthService } from '@array-app/frontend/authentication';
import { BaseApiService } from '@array-app/frontend/common';
import { OrganizationApiService } from '@array-app/frontend/organizations';
import {
	Invite,
	InviteWithOrganization,
	Organization,
	User
} from '@array-app/shared/types';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class InviteApiService extends BaseApiService<Invite> {
	constructor(
		protected override readonly firestore: Firestore,
		protected override readonly authService: AuthService,
		protected override readonly injector: Injector,
		private readonly organizationService: OrganizationApiService,
		private readonly http: HttpClient
	) {
		super(firestore, authService, injector, 'invites');
	}

	/**
	 * @override
	 * inits the invite collection. Overrides since the invite collection doesn't organize by orgs
	 */
	override initialize() {
		try {
			this.collection = collection(
				this.firestore,
				'invites'
			) as CollectionReference<Invite, Invite>;
			const now = Timestamp.now();
			this.collectionQuery = query(
				this.collection,
				where('email', '==', this.authService.authUser$.value?.email),
				where('expiresAt', '>=', now)
			);
		} catch (error) {
			console.error('Error', error);
		}
	}

	/**
	 * Fetches all invites that correlate to the provided email
	 *
	 * @returns array of invites that correlate to this user
	 */
	async fetchAll(): Promise<InviteWithOrganization[]> {
		const orgs: Map<string, Organization | null> = new Map<
			string,
			Organization | null
		>();

		const invites = await getDocs(this.collectionQuery).then((snapshot) =>
			snapshot.docs.map((d) => {
				const value = d.data() as InviteWithOrganization;
				orgs.set(value.organizationId, null);
				return value;
			})
		);

		if (orgs.size) {
			await this.organizationService
				.findByIds(new Array(...orgs.keys()))
				.then((snapshots) =>
					snapshots.docs.forEach((doc) =>
						orgs.set(doc.id, doc.data() as Organization)
					)
				);
		}

		return invites.map((i) => ({
			...i,
			organization: orgs.get(i.organizationId) ?? null
		}));
	}

	accept$(id: string, authId: string): Observable<User> {
		return this.http.post<User>(`${this.baseUrl}/v1/invites/${id}/accept`, {
			authId
		});
	}

	reject$(id: string): Observable<null> {
		return this.http.patch<null>(
			`${this.baseUrl}/v1/invites/${id}/reject`,
			{}
		);
	}

	sendInvite$(invite: Partial<Invite>): Observable<Invite> {
		return this.http.post<Invite>(`${this.baseUrl}/v1/invites`, invite);
	}
}
