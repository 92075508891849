import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthService } from '@array-app/frontend/authentication';
import { User } from '@array-app/shared/types';
import { canEditUser } from '@array-app/shared/utils';
import { UserAdminApiService } from '../services';

@Injectable({
	providedIn: 'root'
})
export class UserDetailResolve {
	constructor(
		private readonly userAdminService: UserAdminApiService,
		private readonly authService: AuthService,
		private readonly router: Router
	) {}

	async resolve(route: ActivatedRouteSnapshot) {
		const id = route.params['id'];
		const user = await this.userAdminService
			.findById(id)
			.then((d) => d.data() as User);

		const authUser = this.authService.user$.value;
		if (route.data['checkPermissions'] && authUser) {
			if (!canEditUser(authUser, user)) {
				// todo: add permissions toast to show user
				this.router.navigate(['app/users', user.id]);
			}
		}

		return user;
	}
}
