import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import {
	CollectionReference,
	Firestore,
	Timestamp,
	collection,
	deleteDoc,
	doc,
	getDoc,
	getDocs,
	query,
	setDoc,
	updateDoc,
	where
} from '@angular/fire/firestore';
import { AuthService } from '@array-app/frontend/authentication';
import { BaseApiService } from '@array-app/frontend/common';
import { AuthUser, Invite, LoginAttempt, User } from '@array-app/shared/types';
import { hasPermission, sanitize } from '@array-app/shared/utils';
import { catchError, of } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class UserAdminApiService extends BaseApiService<User> {
	constructor(
		protected override readonly firestore: Firestore,
		protected override readonly authService: AuthService,
		protected override readonly injector: Injector,
		private readonly http: HttpClient
	) {
		super(firestore, authService, injector, 'users');
	}

	/**
	 * @override
	 * Overrides the base class to filter out super admins if the auth user is not a super
	 * @param org the org to fetch the data from
	 */
	override initialize(org: string) {
		super.initialize(org);

		if (this.collectionName) {
			if (org) {
				const queries = [];

				const role = this.authService.user$.value?.role;
				if (role) {
					if (!hasPermission('manage-super-users', role)) {
						queries.push(where('role', '!=', 'super'));
					}

					if (!hasPermission('manage-deleted-users', role)) {
						queries.push(where('deletedAt', '==', null));
					}
				}

				this.collectionQuery = query(this.collection, ...queries);
			} else {
				console.warn('No organization id was provided');
			}
		}
	}

	/**
	 * Utilizes the firebase api to create a user. Due to the complexities that go along
	 * with user creation in conjunction with Firebase Auth, we need to offload the
	 * logic to the backend. Keeping local with angular fire will not suffice our use case.
	 *
	 * @param data the information to create the user account with
	 * @returns the newly created user
	 */
	override create$(data: Partial<User>) {
		return this.http.post<User>(`${this.url}/users`, data);
	}

	/**
	 * Sends a welcome email with the given information to the corresponding user on behalf
	 * of the corresponding organization. The backend needs to handle this due to the complexities
	 * if the welcome message.
	 *
	 * @param id the id of the user to send the welcome email to
	 * @returns The user who received the welcome email
	 */
	sendWelcome$(id: string) {
		return this.http.get<User>(`${this.url}/users/${id}/send-welcome`);
	}

	/**
	 * Updates the corresponding user in Firestore based on the information
	 * that is being passed through.
	 *
	 * @param data the information to update the corresponding user with
	 * @returns the newly updated user data
	 */
	override async update(data: Partial<User>) {
		const snapshot = await getDoc(doc(this.collection, data.id)).catch(
			(error) => {
				console.error(error);
				throw new Error('User record not found.');
			}
		);

		const oldUser = snapshot.data();
		if (oldUser) {
			data.email = oldUser.email;
			data.updatedAt = Timestamp.now();
		}

		await updateDoc(snapshot.ref, sanitize(data)).catch((error) => {
			console.error(error);
			throw new Error('User record could not be updated.');
		});

		return snapshot.ref;
	}

	/**
	 * Enables the user back into the system
	 *
	 * @param id The id of the user to enable
	 */
	async enable(id: string) {
		const userRef = await getDoc(doc(this.collection, id)).catch(
			(error) => {
				console.error('There was an issue finding the user', error);
				throw new Error('User reference could not be found');
			}
		);

		const user = userRef.data() as User;
		if (user?.disabledAt) {
			user.disabledAt = null;
		} else {
			return user;
		}

		try {
			const authSnapshot = await getDoc(
				doc(
					collection(
						this.firestore,
						'authUsers'
					) as CollectionReference<AuthUser>,
					user.authId
				)
			);
			const authUser = authSnapshot.data() as AuthUser;
			const index = authUser.users.findIndex((user) => user.id === id);
			authUser.users[index].disabledAt = null;
			await updateDoc(authSnapshot.ref, { ...authUser });
		} catch (error) {
			console.error('There was an issue disabling the auth user', error);
		}

		await setDoc(userRef.ref, user).catch((error) => {
			console.error(error);
			throw new Error('There was an issue enabling the user');
		});

		return user;
	}

	/**
	 * Disables the user. Mimics delete but allows the admins to see see the user in their list.
	 *
	 * @param id The id of the user to disable
	 */
	async disable(id: string) {
		const userRef = await getDoc(doc(this.collection, id)).catch(
			(error) => {
				console.error('There was an issue finding the user', error);
				throw new Error('User reference could not be found');
			}
		);

		const user = userRef.data() as User;
		user.disabledAt = Timestamp.now();

		try {
			const authSnapshot = await getDoc(
				doc(
					collection(
						this.firestore,
						'authUsers'
					) as CollectionReference<AuthUser>,
					user.authId
				)
			);
			const authUser = authSnapshot.data() as AuthUser;
			const index = authUser.users.findIndex((user) => user.id === id);
			authUser.users[index].disabledAt = user.disabledAt;
			await updateDoc(authSnapshot.ref, { ...authUser });
		} catch (error) {
			console.error('There was an issue disabling the auth user', error);
		}

		await updateDoc(userRef.ref, {
			disabledAt: user.disabledAt
		}).catch((error) => {
			console.error(error);
			throw new Error('There was an issue disabling the user');
		});

		return user;
	}

	/**
	 * @override
	 * Deletes the user out of the organization. If the user has an auth object,
	 * then the user will be soft deleted. If there is no auth user established yet
	 * then the user will be hard deleted w/ all data recursively deleted by the api
	 *
	 * @param id the id of the user to delete
	 * @returns an empty object if successful
	 */
	override async delete(id: string) {
		try {
			const now = Timestamp.now();
			const snapshot = await getDoc(doc(this.collection, id));
			const user = snapshot.data() as User;

			getDocs(
				query(
					collection(
						this.firestore,
						'invites'
					) as CollectionReference<Invite>,
					where('userId', '==', user.id)
				)
			)
				.then((invites) =>
					invites?.docs.forEach((invite) => deleteDoc(invite.ref))
				)
				.catch((error) => {
					console.error(
						'There was an issue removing the users invites',
						error
					);
				});

			if (user.authId) {
				await updateDoc(snapshot.ref, {
					updatedAt: now,
					deletedAt: now
				});

				const authSnapshot = await getDoc(
					doc(
						collection(
							this.firestore,
							'authUsers'
						) as CollectionReference<AuthUser>,
						user.authId
					)
				);
				const authUser = authSnapshot.data() as AuthUser;
				const index = authUser.users.findIndex(
					(user) => user.id === id
				);
				authUser.users[index].deletedAt = now;
				await updateDoc(authSnapshot.ref, authUser);
			} else {
				await deleteDoc(snapshot.ref);
				this.http.delete(`${this.url}/users/${id}`).pipe(
					catchError((error) => {
						console.error(
							'There was an issue cleaning the user document',
							error
						);
						return of();
					})
				);
			}
		} catch (error) {
			console.error('There was an issue deleting the user', error);
		}

		return {};
	}

	/**
	 * Restores a user within the organization to allow them access to the org again. This
	 * restores them within the org and removes the deletedAt value on their authUser.
	 *
	 * @param id the id of the user to restore
	 * @returns the newly restored user
	 */
	async restore(id: string) {
		try {
			const now = Timestamp.now();
			const snapshot = await getDoc(doc(this.collection, id));
			const user = snapshot.data() as User;

			if (user.authId) {
				user.updatedAt = now;
				user.deletedAt = null;

				await updateDoc(snapshot.ref, user);

				const authSnapshot = await getDoc(
					doc(
						collection(
							this.firestore,
							'authUsers'
						) as CollectionReference<AuthUser>,
						user.authId
					)
				);
				const authUser = authSnapshot.data() as AuthUser;
				const index = authUser.users.findIndex(
					(user) => user.id === id
				);
				authUser.users[index].deletedAt = null;
				await updateDoc(authSnapshot.ref, authUser);
				return user;
			} else {
				throw new Error('The user does not have an auth Id to restore');
			}
		} catch (error) {
			console.error(error);
			throw new Error('There was an issue restoring the user');
		}
	}

	/**
	 * Checks against the current organizations users and determines if a user already exists
	 * in the org for the given email. This only checks against the current org, so if the email
	 * does exist in another org and/or as an auth user but not yet in this org, this will return
	 * `false`.
	 *
	 * @param email the email to check the existence of
	 * @returns boolean depicting if the the email exists or not `true` for yes, `false` for no.
	 */
	async checkEmailExists(email: string) {
		if (!email) {
			return false;
		}

		const value = email.toLowerCase().trim();
		const users = await this.data;
		return users.some((user) => user.email.toLowerCase().trim() === value);
	}

	/**
	 * Fetches the entire list of login attempts for a provided email.
	 *
	 * @param email the email to fetch the login attempts for
	 * @returns array of login attempts for the specified user
	 */
	fetchLoginAttempts(email: string) {
		return getDocs(
			query(
				collection(
					this.firestore,
					'loginAttempts'
				) as CollectionReference<LoginAttempt>,
				where('email', '==', email)
			)
		);
	}
}
