<app-card [hideHeader]="true">
	<app-user-avatar size="medium" [user]="user"></app-user-avatar>
	<div class="info">
		<h4 class="margin-top-0 margin-bottom-1" [title]="user | fullName">
			{{ user | fullName }}
		</h4>
		<p
			*ngIf="user.position"
			class="active small text-tertiary margin-0"
			[title]="user.position"
		>
			{{ user.position }}
		</p>

		<!-- <ng-container *ngIf="!user.lastSignInTime">
			<p class="active small text-tertiary margin-0">Never signed in</p>
		</ng-container>
		<ng-container *ngIf="user.lastSignInTime">
			<p class="active small text-tertiary margin-0">
				Active {{ user.lastSignInTime || '' | relativeTime }}
			</p>
		</ng-container> -->
	</div>
</app-card>
