import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { LoadingSpinnerComponent } from '@array-app/frontend/common';
import { User } from '@array-app/shared/types';
import { InitialsPipe } from '../../pipes';

@Component({
	standalone: true,
	selector: 'app-user-avatar',
	imports: [CommonModule, InitialsPipe, LoadingSpinnerComponent],
	templateUrl: 'user-avatar.component.html',
	styleUrls: ['user-avatar.component.scss']
})
export class UserAvatarComponent {
	@Input() user: User | null = null;

	@Input() loading = false;

	@HostBinding('class')
	@Input()
	size: 'xsmall' | 'small' | 'medium' | 'large' = 'small';
}
