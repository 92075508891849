import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { getDocs } from '@angular/fire/firestore';
import { RouterModule } from '@angular/router';
import {
	DestroyService,
	SearchWrapperComponent,
	SelectBaseMenuComponent,
	ValueSelect
} from '@array-app/frontend/common';
import {
	ResourceListEmptyStateComponent,
	ResourceListTableComponent
} from '@array-app/frontend/resource-list';
import { CardHeaderComponent } from '@array-app/frontend/ui/component';
import { User } from '@array-app/shared/types';
import { userSearch } from '@array-app/shared/utils';
import { TranslateModule } from '@ngx-translate/core';
import { UserAdminApiService } from '../../services';
import { ResourceListUserItemComponent } from '../resource-list-user-item/resource-list-user-item.component';
import { UserStatusComponent } from '../user-status/user-status.component';

@Component({
	standalone: true,
	imports: [
		CommonModule,
		TranslateModule,
		SearchWrapperComponent,
		CardHeaderComponent,
		ResourceListEmptyStateComponent,
		ResourceListTableComponent,
		ResourceListUserItemComponent,
		UserStatusComponent,
		RouterModule
	],
	selector: 'app-select-user-menu',
	templateUrl: 'select-user-menu.component.html',
	styleUrls: ['select-user-menu.component.scss']
})
export class SelectUserMenuComponent extends SelectBaseMenuComponent<User> {
	constructor(
		protected override readonly destroy$: DestroyService,
		private readonly userAdminService: UserAdminApiService
	) {
		super(destroy$);
	}

	/**
	 * Fetches all of the users once and stores them for quick indexing.
	 * Todo: update logic to fetch users every time search changes. Maybe switch
	 * to Algolia.
	 */
	override async fetchContent() {
		this.loading = true;
		getDocs(this.userAdminService.collectionQuery).then((docs) => {
			docs.forEach((doc) =>
				this.allContent.push({
					...(doc.data() as ValueSelect<User>),
					value: doc.ref
				})
			);

			this.initSearch();
			this.loading = false;
		});
	}

	/**
	 * "initializes" the search based on the new search term.
	 */
	override initSearch(search = this.search) {
		this.content = userSearch(
			this.allContent,
			search
		) as ValueSelect<User>[];
	}
}
