import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RelativeTimePipe } from '@array-app/frontend/common';
import { CardComponent } from '@array-app/frontend/ui/component';
import { User } from '@array-app/shared/types';
import { FullNamePipe } from '../../pipes';
import { UserAvatarComponent } from '../user-avatar/user-avatar.component';
import { UserStatusComponent } from '../user-status/user-status.component';

@Component({
	standalone: true,
	selector: 'app-user-card',
	imports: [
		CardComponent,
		UserAvatarComponent,
		CommonModule,
		UserStatusComponent,
		FullNamePipe,
		RelativeTimePipe
	],
	templateUrl: 'user-card.component.html',
	styleUrls: ['user-card.component.scss']
})
export class UserCardComponent {
	@Input() user!: User;
}
