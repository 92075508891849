import { Pipe, PipeTransform } from '@angular/core';
import { User } from '@array-app/shared/types';
import { AuthService } from '@array-app/frontend/authentication';

/**
 * Determines if the user provided is a super user or not. If no value is provided we will
 * use the currently authenticated user to determine.
 *
 * For use on current auth user
 * {{ null | isSuper }}
 */
@Pipe({
	standalone: true,
	name: 'isSuper'
})
export class IsSuperPipe implements PipeTransform {
	constructor(private readonly authService: AuthService) {}
	transform(user: User | null) {
		if (!user) {
			return this.authService.user$.value?.role === 'super';
		} else {
			return user.role === 'super';
		}
	}
}
