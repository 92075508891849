import { Pipe, PipeTransform } from '@angular/core';
import { User } from '@array-app/shared/types';
import { canEditUser } from '@array-app/shared/utils';
import { AuthService } from '@array-app/frontend/authentication';

@Pipe({
	standalone: true,
	name: 'canEditUser'
})
export class CanEditUserPipe implements PipeTransform {
	constructor(private readonly authService: AuthService) {}

	transform(user: User) {
		if (this.authService.user$.value) {
			return canEditUser(this.authService.user$.value, user);
		}
		return false;
	}
}
