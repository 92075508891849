import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '@array-app/frontend/authentication';
import { Permissions } from '@array-app/shared/types';

@Pipe({
	standalone: true,
	name: 'permissionAccess'
})
export class PermissionAccessPipe implements PipeTransform {
	constructor(private readonly authService: AuthService) {}

	transform(permissions: (keyof Permissions)[]) {
		const user = this.authService.user$.value;
		if (user?.role === 'owner' || user?.role === 'super') {
			return true;
		}

		return permissions?.length
			? permissions.every(
					(p) => this.authService.user$.value?.permissions[p]
			  )
			: true;
	}
}
