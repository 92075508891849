import { Pipe, PipeTransform } from '@angular/core';
import { AlgoliaUser, User } from '@array-app/shared/types';
import { initials } from '@array-app/shared/utils';

@Pipe({
	standalone: true,
	name: 'initials'
})
export class InitialsPipe implements PipeTransform {
	transform(user: User | AlgoliaUser) {
		return initials(user);
	}
}
