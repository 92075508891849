import { Pipe, PipeTransform } from '@angular/core';
import UAParser from 'ua-parser-js';

/**
 * Used to format the userAgent data within a uses login activity if the
 * data is available to do so. The intended implementation is for the output
 * of this pipe to be consumed from within a MatTooltip component.
 *
 * @usage
 * <div [matTooltip]="attempt.userAgent | loginUserAgent"></div>
 */
@Pipe({
	standalone: true,
	name: 'loginUserAgent'
})
export class LoginUserAgentPipe implements PipeTransform {
	transform(value: string): string {
		const ua = value ? UAParser(value) : null;
		if (ua) {
			return `Browser: ${ua.browser?.name ?? 'not tracked'}\n
                OS: ${ua.os?.name ?? 'not tracked'}
                OS version: ${ua.os?.version ?? 'not tracked'}
                CPU architecture: ${ua.cpu?.architecture ?? 'not tracked'}\n
                Device type: ${ua.device?.type ?? 'not tracked'}
                Device vendor: ${ua.device?.vendor ?? 'not tracked'}
                Device model: ${ua.device?.model ?? 'not tracked'}\n`;
		}

		return '';
	}
}
