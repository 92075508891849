import { Injectable } from '@angular/core';

import { AuthService } from '@array-app/frontend/authentication';

@Injectable({
	providedIn: 'root'
})
export class AuthUserDetailResolve {
	constructor(private readonly authService: AuthService) {}

	async resolve() {
		if (this.authService.user$.value) {
			return Promise.resolve(this.authService.user$.value);
		}

		const user = await this.authService.getUser();
		if (!user) {
			return this.authService.logout();
		}

		return user;
	}
}
