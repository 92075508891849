<app-card [header]="'form.shared.created-by' | translate">
	<div class="content">
		<ng-container *ngIf="!loading; else loader">
			<app-user-avatar *ngIf="creator" [user]="creator"></app-user-avatar>
			<div class="info">
				<ng-container *ngIf="creator | fullName as name">
					<div class="margin-top-0 margin-bottom-1 header">
						<h4 class="margin-0" [title]="name">
							{{ name }}
						</h4>
						<div
							*ngIf="fromOtherOrganization"
							class="icon-container"
							[matTooltip]="
								'This user is from another organization.'
							"
						>
							<i class="fa-solid fa-circle-info"></i>
						</div>
					</div>
				</ng-container>
				<ng-container *ngIf="!creator">
					<h4
						class="margin-top-0 margin-bottom-1"
						[title]="'users.not-found' | translate"
					>
						{{ 'users.not-found' | translate }}
					</h4>
				</ng-container>
				<ng-container *ngIf="createdAt | formatTimestamp as timestamp">
					<p class="margin-0" [title]="timestamp">
						{{ timestamp }}
					</p>
				</ng-container>
			</div>
		</ng-container>
	</div>
</app-card>

<ng-template #loader>
	<app-loading-spinner></app-loading-spinner>
</ng-template>
