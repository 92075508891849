import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Role } from '@array-app/shared/types';
import { AuthService } from '@array-app/frontend/authentication';

@Injectable({
	providedIn: 'root'
})
export class UserRoleGuard {
	readonly supportedRoles: Role[] = ['user', 'admin', 'owner', 'super'];

	constructor(
		private readonly authService: AuthService,
		private readonly router: Router
	) {}

	async canActivate() {
		await this.authService.setupAuthUser();
		const role = this.authService.user$.value?.role;
		if (role && this.supportedRoles.includes(role)) {
			return true;
		}
		return this.router.parseUrl('/app');
	}
}
