import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthService } from '@array-app/frontend/authentication';
import { OrganizationApiService } from '@array-app/frontend/organizations';
import { Invite, Organization } from '@array-app/shared/types';
import { TranslateService } from '@ngx-translate/core';
import { InviteApiService } from '../services';

@Injectable({
	providedIn: 'root'
})
export class InviteDetailResolve {
	constructor(
		private readonly inviteService: InviteApiService,
		private readonly organizationApiService: OrganizationApiService,
		private readonly authService: AuthService,
		private readonly router: Router,
		private readonly snackbar: MatSnackBar,
		private readonly translate: TranslateService
	) {}

	async resolve(route: ActivatedRouteSnapshot) {
		const id = route.params['id'];
		const invite = await this.inviteService
			.findById(id)
			.then((d) => d.data() as Invite);

		if (!invite) {
			this.snackbar.open(
				this.translate.instant('invite.not-found'),
				this.translate.instant('generic.ok')
			);
			this.router.navigate(['']);
			return;
		}

		const expiresAtSeconds = invite.expiresAt.seconds;
		if (expiresAtSeconds < new Date().valueOf() / 1000) {
			this.snackbar.open(
				this.translate.instant('invite.expired'),
				this.translate.instant('generic.ok')
			);
			return;
		}

		// if the invite has an auth id tied to it, then the user already exists in firebase so check if they are signed in
		const authUser = this.authService.authUser$.value;
		if (invite.authId) {
			if (authUser && invite.authId !== authUser.id) {
				this.snackbar.open(
					this.translate.instant('invite.wrong-user', {
						email: invite.email
					}),
					this.translate.instant('generic.ok')
				);
				this.router.navigate(['app']);
				return;
			} else if (!authUser) {
				this.snackbar.open(
					this.translate.instant('invite.no-user-found', {
						email: invite.email
					}),
					this.translate.instant('generic.ok')
				);
				this.router.navigate(['auth']);
				return;
			}
		} else if (authUser) {
			this.snackbar.open(
				this.translate.instant('invite.log-out-user'),
				this.translate.instant('generic.ok')
			);
			this.router.navigate(['app']);
		}

		const organization = await this.organizationApiService
			.findById(invite.organizationId)
			.then((doc) => doc.data() as Organization);

		return {
			invite,
			organization
		};
	}
}
