<div
	class="photo"
	[class.visible]="user?.photoURL"
	[ngStyle]="
		user?.photoURL
			? { 'background-image': 'url(' + user?.photoURL + ')' }
			: {}
	"
></div>

<ng-container *ngIf="!loading && user">
	<p>{{ user | initials }}</p>
</ng-container>

<ng-container *ngIf="loading">
	<app-loading-spinner type="chromatic"></app-loading-spinner>
</ng-container>
