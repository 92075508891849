import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { TextBadgeComponent } from '@array-app/frontend/common';
import { AlgoliaUser, Status, StatusType } from '@array-app/shared/types';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	standalone: true,
	selector: 'app-algolia-user-status',
	imports: [CommonModule, TextBadgeComponent, TranslateModule],
	templateUrl: 'algolia-user-status.component.html',
	styleUrls: ['algolia-user-status.component.scss']
})
export class AlgoliaUserStatusComponent {
	@Input() user!: AlgoliaUser;

	@HostBinding('class')
	@Input()
	size: 'medium' | 'small' = 'medium';

	get status(): { type: StatusType; text: string } {
		switch (this.user.status) {
			case 'rejected':
				return {
					type: Status.Danger,
					text: 'users.status.rejected'
				};
			case 'disabled':
				return {
					type: Status.Danger,
					text: 'users.status.disabled'
				};
			case 'pending':
				return {
					type: Status.Caution,
					text: 'users.status.pending'
				};
			default:
			case 'active':
				return {
					type: Status.Success,
					text: 'users.status.enabled'
				};
		}
	}
}
