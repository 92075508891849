import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@array-app/frontend/authentication';

@Injectable({
	providedIn: 'root'
})
export class SuperRoleGuard {
	constructor(
		private readonly authService: AuthService,
		private readonly router: Router
	) {}

	async canActivate() {
		await this.authService.setupAuthUser();
		const role = this.authService.user$.value?.role;
		if (role && role === 'super') {
			return true;
		}
		return this.router.parseUrl('/app');
	}
}
