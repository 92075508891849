<app-organization-wordmark
	*ngIf="invite.organization"
	[organization]="invite.organization"
></app-organization-wordmark>

<div class="info">
	<p class="margin-top-0 small text-tertiary">
		<ng-container
			*ngIf="
				!isExpired &&
				(invite.expiresAt | relativeTimestamp: true) as expires
			"
		>
			{{
				'time.relative-time-with-expiration'
					| translate
						: {
								time: invite.createdAt | relativeTimestamp,
								expires:
									'time.expires-time' | translate: { expires }
						  }
			}}
		</ng-container>

		<ng-container *ngIf="isExpired || !invite.expiresAt">
			{{ invite.createdAt | relativeTimestamp }}
		</ng-container>

		<app-text-badge *ngIf="isExpired" class="margin-left-2" type="danger">
			{{ 'generic.expired' | translate }}
		</app-text-badge>
	</p>

	<p>
		{{
			'invite.subheader'
				| translate: { organization: invite.organization?.name }
		}}
	</p>
</div>

<div class="actions">
	<ng-container *ngIf="allowNavigate">
		<button class="icon small secondary" (click)="onNavigateTo()">
			<i class="fa-solid fa-check-double"></i>
			{{ 'form.actions.switch-organization' | translate }}
		</button>
	</ng-container>

	<ng-container *ngIf="!allowNavigate">
		<button
			[class.loading]="busy"
			[disabled]="busy || isExpired"
			class="button icon secondary"
			(click)="onDecline()"
		>
			<i class="fa-solid fa-xmark"></i>
			{{ 'generic.decline' | translate }}
			<app-loading-spinner type="chromatic"></app-loading-spinner>
		</button>

		<button
			[class.loading]="busy"
			[disabled]="busy || isExpired"
			class="button icon primary"
			(click)="onAccept()"
		>
			<i class="fa-solid fa-check"></i>
			{{ 'generic.accept' | translate }}
			<app-loading-spinner type="chromatic"></app-loading-spinner>
		</button>
	</ng-container>
</div>
