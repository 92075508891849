import { Pipe, PipeTransform } from '@angular/core';
import { User } from '@array-app/shared/types';
import { status, StatusOptions } from '@array-app/shared/utils';

@Pipe({
	standalone: true,
	name: 'userStatus'
})
export class UserStatusPipe implements PipeTransform {
	transform(user: User, options?: StatusOptions) {
		return status(user, options);
	}
}
