import { CommonModule, DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthService } from '@array-app/frontend/authentication';
import {
	FormatDatePipe,
	FormatTimestampPipe,
	LoadingSpinnerComponent
} from '@array-app/frontend/common';
import { CardComponent } from '@array-app/frontend/ui/component';
import { User } from '@array-app/shared/types';
import { TranslateModule } from '@ngx-translate/core';
import { FullNamePipe } from '../../pipes';
import { UserAdminApiService } from '../../services';
import { UserAvatarComponent } from '../user-avatar/user-avatar.component';

@Component({
	standalone: true,
	selector: 'app-creator-card',
	imports: [
		CardComponent,
		UserAvatarComponent,
		CommonModule,
		FullNamePipe,
		LoadingSpinnerComponent,
		FormatDatePipe,
		FormatTimestampPipe,
		TranslateModule,
		MatTooltipModule
	],
	templateUrl: 'creator-card.component.html',
	styleUrls: ['creator-card.component.scss'],
	providers: [DatePipe]
})
export class CreatorCardComponent implements OnInit {
	/**
	 * `id` of the user who created the data
	 */
	@Input() createdBy!: string;
	/**
	 * The time that the item was created at
	 * Firebase will return either `seconds` or `_seconds`.
	 */
	@Input() createdAt!: any;
	/**
	 * The organization id of where the creator originates from
	 */
	@Input() organizationId: string | null = null;

	/**
	 * The user who corresponds to the `createdBy` id
	 */
	creator!: User;
	/**
	 * `true` if the creator is loading
	 */
	loading = true;
	/**
	 * `true` if the creator is from a different organization
	 */
	fromOtherOrganization = false;

	constructor(
		private readonly userService: UserAdminApiService,
		private readonly authService: AuthService
	) {}

	ngOnInit() {
		const organization = this.authService.organization$.value;
		if (!this.organizationId && organization) {
			this.organizationId = organization.id;
		}

		if (this.organizationId !== this.authService.organization$.value?.id) {
			this.fromOtherOrganization = true;
		}

		if (this.createdBy) {
			try {
				this.userService
					.findById(this.createdBy, this.organizationId)
					.then((value) => {
						const user = value?.data();
						if (user) {
							this.creator = user;
							this.loading = false;
						}
					});
			} catch (error) {
				this.loading = false;
				console.error(
					'There was an issue fetching the creator of this item',
					error
				);
			}
		} else {
			this.loading = false;
		}
	}
}
