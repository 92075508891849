import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthService } from '@array-app/frontend/authentication';

@Injectable({
	providedIn: 'root'
})
export class AppUserDataResolve {
	constructor(
		private readonly authService: AuthService,
		private readonly router: Router
	) {}

	async resolve(route: ActivatedRouteSnapshot) {
		await this.authService.setupAuthUser();
		if (
			!this.authService.user$.value &&
			!route.url[0].path.startsWith('invite')
		) {
			this.router.navigate(['login']);
			return;
		}

		if (
			!route.url[0].path.startsWith('logout') &&
			!route.url[0].path.startsWith('invite') &&
			!route.url[0].path.startsWith('app')
		) {
			this.router.navigate(['app']);
		}
		return;
	}
}
